import React from 'react';

import { Class10CentumWinners2024 } from '../views';

const ClassAssembly11CPage = () => {
    return <Class10CentumWinners2024 />;
  };
  
  export default ClassAssembly11CPage;

  //11C